import GameClient from '../GameClient/GameClient';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo, useState } from 'preact/hooks';
import { datadogRum } from '@datadog/browser-rum';
import { createContext, h } from 'preact';
import { setGameLoadComplete } from '../../redux/actions/game-window';
import { gameStudioSelector, showGameWindowSelector } from '../../selectors/game-window';
import {
    isGameWindowDisabled,
    isIosWrapper,
    isRunningInsideWrapper,
    sendWrapperEvent
} from '../../common/wrapper-bridge-mobile';
import { MESSAGES, RGIS } from '../../common/constants';
import Layout from './components/Layout/Layout';
import { setWrapperAppConfig } from '../../common/helpers/cookies';
import { getAppPlatform } from '../../common/helpers/app-platform';
import { PLATFORM } from '../../common/constants/game-constants';
import { useNativeBack } from '../../common/hooks/useNativeBack';
import { useOrientation } from '../../common/hooks/useOrientation';

import { MessageOverlay } from '../../common/hooks/use-showoff/components/MessageOverlay';
import { usePageVisibility } from 'react-page-visibility';
import { usePrevious } from '../../game-window/hooks/usePrevious';
import { GameLauncher } from '../Launcher/GameLauncher';
import { rgiSelector, nativeIdSelector } from '../../selectors/gameContext/index';
import { useInject } from 'inversify-hooks';
import { DI_SERVICE } from '../../dependency-injection/constants';
import { useGameClientProxy } from '../Launcher/GameClient/hooks/useGameClientProxy';
import useCheckEligibilityForGameWindow from '../../common/helpers/game-window-eligibility';
import { useLogTracking } from '../../common/hooks/use-user-tracking/useUserTracking';
import { MODULES, ZONES, getAmplitudeKey } from '../../common/hooks/use-user-tracking/types';
import { DisplayQueue } from './components/DisplayQueue/DisplayQueue';
import { LoadingContainer } from '../Launcher/LoadingScreen/LoadingContainer';
import { ddRumVitalDuration, VitalDurationType } from '../../common/helpers/datadog-wrapper';
import { ddVitalGameLaunchDuration } from '../../common/datadog/game-launch/game-launch-duration';

export const GameWindowContext = createContext({});

const GameWindow = ({ gameProvider }) => {
    require(`./styles/${process.env.APPLICATION}/style.scss`);
    useNativeBack();

    const dispatch = useDispatch();
    const showGameWindowState = useSelector(showGameWindowSelector);
    const { logTrackingComponent } = useLogTracking();
    const isEligible = useCheckEligibilityForGameWindow();
    const showGameWindow = useMemo(() => showGameWindowState || isEligible, [showGameWindowState, isEligible]);
    const locationVerified = useSelector(state => state?.geocomply?.location?.verified);
    const isMenuOpen = useSelector(state => state?.displayMenu?.displayMenu);
    const rgi = useSelector(rgiSelector);
    const isSpinInProgress = useSelector(state => state?.gameWindow?.spinInProgress);
    const gameLoaded = useSelector(state => state?.gameWindow?.gameLoaded);
    const nativeId = useSelector(nativeIdSelector);
    const gameStudio = useSelector(gameStudioSelector);
    const [showoffParams, setShowoffParams] = useState({});

    const {
        toggleHelp,
        togglePaytable,
        toggleAudio,
        reloadBalance,
        dismissErrorMessage,
        exitGame,
        chooseDialog,
    } = useGameClientProxy(true);
    const isAndroidWrapper = isRunningInsideWrapper();
    const disableGameWindow = isGameWindowDisabled();
    const { isPortrait, deviceType } = useOrientation();
    const [getBalanceData] = useInject(DI_SERVICE.balanceData);

    const isVisible = usePageVisibility();
    const previousIsVisible = usePrevious(isVisible);

    useEffect(() => {
        if (isSpinInProgress && !gameLoaded) {
            dispatch(setGameLoadComplete(true));
            datadogRum.addAction('SPIN_IN_PROGRESS_AND_GAME_NOT_LOADED');
        }
    }, [gameLoaded, isSpinInProgress, dispatch]);

    useEffect(() => {
        if (nativeId === '-1') {
            dispatch(setGameLoadComplete(true));
        }
    });

    useEffect(() => {
        if (gameLoaded) {
            ddRumVitalDuration(VitalDurationType.STOP, ddVitalGameLaunchDuration({gameStudio}))
        }
    }, [gameLoaded, gameStudio]);

    useEffect(() => {
        if (isVisible && !previousIsVisible) {
            dispatch(getBalanceData());
            reloadBalance();
            if (isAndroidWrapper) {
                window.dispatchEvent(new Event('resize'));
            }
        }
    }, [getBalanceData, isVisible, previousIsVisible, reloadBalance, dispatch, isAndroidWrapper]);

    useEffect(() => {
        if (isMenuOpen) {
            logTrackingComponent({
                [getAmplitudeKey('EVENT')]: 'In Game Menu Opened',
                [getAmplitudeKey('MODULE')]: MODULES.GW,
                [getAmplitudeKey('ZONE_NAME')]: ZONES.DASHBOARD
            });
        }
    }, [logTrackingComponent, isMenuOpen]);

    useEffect(() => {
        if (isAndroidWrapper && locationVerified) {
            sendWrapperEvent(MESSAGES.HIDE_HEADER, '');
        }
    }, [isAndroidWrapper, locationVerified]);

    useEffect(() => {
        if (isAndroidWrapper && getAppPlatform() === PLATFORM.Xsell) {
            setWrapperAppConfig();
        }
    }, [isAndroidWrapper]);

    return (
        <GameWindowContext.Provider
            value={{
                deviceType,
                isMobile: isAndroidWrapper,
                isPortrait,
                reloadBalance,
                showGameWindow,
                toggleAudio,
                toggleHelp,
                togglePaytable,
                dismissErrorMessage,
                exitGame,
                showoffParams,
                setShowoffParams,
                disableGameWindow,
                chooseDialog,
            }}
        >

            <Layout>
                <GameClient isMenuOpen={isMenuOpen}>
                    <GameLauncher provider={gameProvider} rgi={rgi} />
                </GameClient>
            </Layout>
            <MessageOverlay />
            {isIosWrapper() || rgi === RGIS.INCENTIVE_GAMES || nativeId === '-1' ? null : <LoadingContainer />}
            {!disableGameWindow && <DisplayQueue />}
        </GameWindowContext.Provider>
    );
};

export default GameWindow;
