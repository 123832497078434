import { useContext } from 'preact/hooks';
import Panel from '../Panel/Panel';
import { GameWindowContext } from '../../GameWindow';
import { useSelector } from 'react-redux';
import { swjJackpotIdSelector } from '../../../../selectors/site-wide-jackpot';

const Layout = ({ children }) => {
    require(`./styles/${process.env.APPLICATION}/style.scss`);
    const { isPortrait, disableGameWindow, showGameWindow } = useContext(GameWindowContext);

    const isRewardsMachine = useSelector(state => state?.gameWindow?.isRewardsMachine);
    const jackpotId = useSelector(swjJackpotIdSelector);
    const url = new URL(window.location.href);
    const jackpotModeParameter = url.searchParams.get('jackpotMode') === 'true'; // get url jackpotMode query parameter
    const isSwjEnabled = jackpotModeParameter;
    const positions = {
        first: isPortrait ? 'top' : 'left',
        second: isPortrait ? 'bottom' : 'right',
        swjEnabled: isPortrait ? null : 'top swj'
    };

    return (
        <div
            id="game-launcher-wrapper"
            className={`game-launcher-wrapper ${isPortrait ? 'portrait' : ''}`}
        >
            {!isRewardsMachine && !disableGameWindow && (
                <Panel position={positions.first} jackpotId={jackpotId} isSwjEnabled={isSwjEnabled} />
            )}
            <div className="center-container">
                {!isRewardsMachine && !disableGameWindow && jackpotId && isSwjEnabled && showGameWindow && (
                    <Panel position={positions.swjEnabled} jackpotId={jackpotId} isSwjEnabled={isSwjEnabled} />
                )}
                {children}
            </div>
            {!isRewardsMachine && !disableGameWindow && (
                <Panel position={positions.second} jackpotId={jackpotId} isSwjEnabled={isSwjEnabled} />
            )}
        </div>
    );
};

export default Layout;
