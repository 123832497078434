interface IGameFetchDuration {
    data?: Record<string, unknown>
    provider?: string;
    brand?: string;
    platform?: string;
    gameName?: string;
    gameUid?: string;
    channel?: string;
}

export const ddVitalGameFetchDuration = (context?: IGameFetchDuration) => ({
    name: 'GAME_FETCH_DURATION',
    description: 'Game fetch duration',
    context
})

