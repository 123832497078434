import { IddRumAction } from '../../helpers/datadog-wrapper';
import { IddUseCases } from '../enums';

interface IRmGameRedirect {
    gameId: string;
}

export const ddActionRmGameLaunchRedirect = ({ ...context }: IRmGameRedirect, use_case: IddUseCases): IddRumAction => ({
    name: 'RM_GAME_LAUNCH_REDIRECT',
    description: 'RM game redirect event',
    context,
    use_case
});
