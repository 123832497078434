interface IGameLaunchDuration {
    gameUid: string;
    brand: string;
    platform: string;
    gameProvider: string;
    channel: string;
    gameStudio?: string;
}

export const ddVitalGameLaunchDuration = (context?: IGameLaunchDuration) => ({
    name: 'GAME_LAUNCH_DURATION',
    description: 'Game launch duration',
    context
})

