/* eslint-disable sonarjs/cognitive-complexity */
import { datadogRum } from '@datadog/browser-rum';
import { FabricServicesAxios, getFabricEndpoint, getFabricURL } from '../../../common/axios/FabricServicesAxios';
import { AGGREGATION_HEADERS } from '../../../common/constants';
import { DD_USE_CASES } from '../../../common/datadog/enums';
import { ddActionGetGameWindowReceived } from '../../../common/datadog/get-game/gg-window-received';
import connectionType from '../../../common/helpers/connectionType';
import { getRegion } from '../../../common/helpers/cookies';
import { ddRumAction, ddRumVitalDuration, VitalDurationType } from '../../../common/helpers/datadog-wrapper';
import { FetchType, getRequestUid } from '../../../common/helpers/fetch-helper';
import { getQueryParameter } from '../../../common/helpers/queryparams';
import { fetchEnd, fetchError, fetchStart } from '../../fetch/fetch';
import { types } from '../../types';
import { getBrand } from '../../../common/helpers/brand';
import { getAppPlatform } from '../../../common/helpers/app-platform';
import { Dispatch } from 'react';
import {
    dispatchAmplitudeExperiments,
    dispatchDirectLaunchParams,
    dispatchFeatureFlags,
    dispatchGameContext,
    dispatchGameOverallStatus,
    dispatchGameWindow,
    dispatchJackpotData,
    escapeGameUid
} from './fetch-game-window-helpers';
import { GameWindowData } from './types';
import { INCENTIVE_GAMES } from '../../../common/constants/game-constants';
import { ddVitalGameFetchDuration } from '../../../common/datadog/get-game/game-fetch-duration';
import { getUserChannel } from '../../../common/helpers/platform';

export const fetchGameWindow = (gameUid: string) => async (dispatch: Dispatch<unknown>) => {
    const region = getRegion();
    const brand = getBrand();
    const requestUid = getRequestUid(FetchType.GameWindow);
    const fabricURL = getFabricURL();
    const endpoint = getFabricEndpoint('aggregation', 'aggregation');
    const baseURL = process.env.NODE_CONFIG_ENV === 'local-development' ? `${fabricURL}:4000` : fabricURL;
    const provider = getQueryParameter('providerName');
    const escapedGameUid = gameUid && gameUid !== 'undefined' ? escapeGameUid(gameUid) : undefined;
    const id = escapedGameUid ?? getQueryParameter('gameId');
    const platform = getAppPlatform();
    const channel = getUserChannel();

    const gameId = provider === INCENTIVE_GAMES ? 'incentive' : id ?? 'default';

    try {
        dispatch(fetchStart(requestUid));
        // Note: For Game Launch Speed Datadog dashboard, the values for 'platform' and 'channel' are intentionally reversed.
        // 'platform' represents the channel, and 'channel' represents the platform.
        ddRumVitalDuration(VitalDurationType.START, ddVitalGameFetchDuration({
            provider,
            brand,
            platform:channel,
            channel:platform
        }))
        dispatch({ type: types.GET_GAME_WINDOW_REQUEST });

        const response = await FabricServicesAxios().get<GameWindowData>(endpoint, {
            baseURL,
            params: {
                product: 'casino',
                platform: 'desktop',
                data: 'game-window',
                gameId,
                isGameUid: !!escapedGameUid
            },
            headers: {
                [AGGREGATION_HEADERS.X_BRAND]: brand,
                [AGGREGATION_HEADERS.X_REGION_CODE]: region
            },
            withCredentials: true
        });

        const { data } = response ?? {};

        const { gameInfo } = data?.gameWindow ?? {};
        ddRumVitalDuration(VitalDurationType.STOP, ddVitalGameFetchDuration({gameUid: gameInfo?.gameUid}))

        dispatchFeatureFlags(dispatch, data);

        dispatchAmplitudeExperiments(dispatch, data);

        dispatchGameWindow(dispatch, data);

        dispatchGameContext(dispatch, platform, gameInfo, gameUid);

        dispatchDirectLaunchParams(dispatch, gameInfo);

        dispatchGameOverallStatus(dispatch, gameInfo, provider);

        dispatchJackpotData(dispatch, data);

        ddRumAction(ddActionGetGameWindowReceived({ data: response?.data }, DD_USE_CASES.GAME_LAUNCHER));

        dispatch(fetchEnd(requestUid));
    } catch (error) {
        dispatch({ type: types.GET_GAME_WINDOW_FAILED, payload: { name: error.name } });
        dispatch(fetchError(requestUid));

        datadogRum.addError(
            new Error(error),
            {
                type: 'GAME_WINDOW',
                connectionType: connectionType(),
                ...error
            }
        );
    }
};
