import { useMemo } from 'preact/hooks';
import { useSelector } from 'react-redux';

import { getXsellGeocomplyCookie } from '../../../../common/helpers/cookies';
import { useAppSelector } from '../../../../redux/hooks';
import { experimentsLoadedSelector, experimentsSelector } from '../../../../selectors/amplitude-experiments';
import { clientInfoSelector } from '../../../../selectors/gameClientStatus';
import {
    rgiSelector,
    brandSelector,
    gameNameSelector,
    providerRefSelector,
    ganIdSelector,
    regionSelector,
    gameUidSelector,
    gameIdSelector,
    nativeIdSelector,
    directLaunchParamsSelector
} from '../../../../selectors/gameContext';
import { isIosWrapper } from '../../../../common/wrapper-bridge-mobile';
import { ExtendedGameContext, GameContext } from '../../../../redux/types/game-context.types';

export const useGameClientSelectors = (): GCSelectorsResult => {
    const clientBaseUrl = window.location.hostname.replace('launcher', 'gc');

    const clientUrl = `https://${clientBaseUrl}${process.env.NODE_CONFIG_ENV.includes('local-') ? ':5173' : ''}/`;

    const gameUrl = new URL(clientUrl);

    const { clientReady, handshake: shouldPerformDirectHandshakeIOS } = useSelector(clientInfoSelector);

    const experimentsReady = useSelector(experimentsLoadedSelector);
    const rgi = useSelector(rgiSelector);
    const brand = useSelector(brandSelector);
    const gameName = useSelector(gameNameSelector) || 'Game';
    const providerRef = useSelector(providerRefSelector);
    const ganId = useSelector(ganIdSelector);
    const region = useSelector(regionSelector);
    const gameUid = useSelector(gameUidSelector);
    const gameId = useSelector(gameIdSelector);
    const nativeId = useSelector(nativeIdSelector);
    const userId = useAppSelector(state => state?.session?.userId);
    const amplitudeExperiments = useSelector(experimentsSelector);
    const url = new URL(window.location.href);
    const jackpotModeParameter = url.searchParams.get('jackpotMode') ?? 'false';

    const directLaunchParams = useSelector(directLaunchParamsSelector);
    const xsellGeocomplyToken = getXsellGeocomplyCookie();

    /**
        For iOS web launcher we will deny any response to handshake form within launcher.
        The handshake event will be sent from iOS webview directly and forwarded to client.
     */
    const shouldRespondToHandshake = useMemo(() => {
        if (isIosWrapper()) {
            return shouldPerformDirectHandshakeIOS ? !shouldPerformDirectHandshakeIOS : clientReady;
        }

        return clientReady && experimentsReady;
    }, [clientReady, experimentsReady, shouldPerformDirectHandshakeIOS]);

    return {
        shouldRespondToHandshake,
        gameUrl,
        gameContext: {
            rgi,
            brand,
            gameName,
            providerRef,
            ganId,
            gameUid,
            region,
            gameId,
            nativeId,
            userId,
            amplitudeExperiments,
            directLaunchParams,
            xsellGeocomplyToken,
            jackpotMode: jackpotModeParameter
        }
    };
};

type GCSelectorsResult = {
    shouldRespondToHandshake: boolean;
    gameUrl: URL;
    gameContext: Omit<GameContext, 'provider' | 'overallStatus'> & ExtendedGameContext;
};
