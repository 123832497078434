import { useSelector } from 'react-redux';
import { useEffect } from 'preact/hooks';
import { getAppPlatform } from '../helpers/app-platform';
import {
    providerSelector,
    gameNameSelector,
    gameIdSelector,
    providerRefSelector,
    brandSelector,
    rgiSelector, gameUidSelector
} from '../../selectors/gameContext';
import { ddRumAction, ddRumVitalDuration, VitalDurationType } from '../helpers/datadog-wrapper';
import { ddActionGameLaunchInit } from '../datadog/common/game-launch-init';
import { DD_USE_CASES } from '../datadog/enums';
import { RGIS } from '../constants';
import { ddActionRmGameLaunchInit } from '../datadog/reward-machine/rm-game-launch-init';
import { ddVitalGameLaunchDuration } from '../datadog/game-launch/game-launch-duration';
import { getUserChannel } from '../helpers/platform';

export const useLogGameLaunched = () => {
    const RGI = useSelector(rgiSelector);
    const brand = useSelector(brandSelector);
    const platform = getAppPlatform();
    const gameProvider = useSelector(providerSelector);
    const gameName = useSelector(gameNameSelector);
    const gameId = useSelector(gameIdSelector);
    const gameUid = useSelector(gameUidSelector);
    const providerRef = useSelector(providerRefSelector);
    const channel = getUserChannel();

    useEffect(() => {
        if (
            RGI &&
            brand &&
            platform &&
            gameProvider &&
            gameName &&
            gameId &&
            providerRef &&
            RGI !== RGIS.INCENTIVE_GAMES
        ) {
            if (RGI !== RGIS.INCENTIVE_GAMES) {
                ddRumAction(
                    ddActionGameLaunchInit(
                        {
                            brand,
                            gameId,
                            gameName,
                            gameProvider,
                            platform,
                            providerRef,
                            RGI
                        },
                        DD_USE_CASES.GAME_LAUNCHER
                    )
                );
                // Note: For Game Launch Speed Datadog dashboard, the values for 'platform' and 'channel' are intentionally reversed.
                // 'platform' represents the channel, and 'channel' represents the platform.
                ddRumVitalDuration(VitalDurationType.START, ddVitalGameLaunchDuration(
                    {
                        gameUid,
                        brand,
                        platform: channel,
                        channel: platform,
                        gameProvider
                    })
                );
            } else {
                ddRumAction(ddActionRmGameLaunchInit(DD_USE_CASES.RM_GAME));
            }
        }
    }, [brand, gameUid, channel ,gameId, gameName, gameProvider, platform, providerRef, RGI]);
};
