import { datadogRum } from '@datadog/browser-rum';
import { IddUseCases } from '../datadog/enums';
import { isIosWrapper } from '../wrapper-bridge-mobile';

const isDevelopment = process.env.NODE_ENV === 'development';

interface IddRumBase {
    name: string;
    description: string;
    use_case: IddUseCases;
}

export interface IddRumAction extends IddRumBase {
    context?: object;
}

interface IddRumVitalDurationAction {
    name: string;
    description: string;
    context?: object
}

export enum VitalDurationType {
    START = 'start',
    STOP = 'stop'
}

/**
 * @example
 * const ddActionRmGameMount = (context: { gameUrl?: string }, use_case: IddUseCases): IddRumAction => ({
 *      name: 'RM_GAME_MOUNT',
 *      description: 'RM Open Game',
 *      context,
 *      use_case
 * });
 * ddRumAction(ddActionRmGameMount({ gameUrl: 'url' }, DD_USE_CASES.GLOBAL))
 */
export const ddRumAction = (action: IddRumAction, debug = isDevelopment) => {
    const { name, description, context, use_case } = action;

    if (debug && isDevelopment) {
        // eslint-disable-next-line
        console.log(`DD RUM ACTION [${name}]: ${description} - `, { context, use_case });
    }

    datadogRum.addAction(name, { description, ...context, use_case })
};

/**
 * @example
 * const ddVitalGameLaunchDuration = (context?: IGameLaunchDuration): IddRumVitalDurationAction => ({
 *        name: 'GAME_LAUNCH_DURATION',
 *         description: 'Game launch duration',
 *         context
 * });
 * ddRumVitalDuration(VitalDurationType.START,ddVitalGameLaunchDuration(gameUid, brand, platform, gameProvider, channel))
 * ddRumVitalDuration(VitalDurationType.END,ddVitalGameLaunchDuration())
 */
const VITAL_TIMEOUT = 90000; // 90 seconds fallback to auto-stop vital
const activeVitals = new Map<string, number>(); // Tracks active vitals with their timeout IDs

export const ddRumVitalDuration = (
    type: VitalDurationType,
    action: IddRumVitalDurationAction,
    allowIosWrapperMeasurement = false,
    debug = isDevelopment
) => {
    const { name, description, context } = action;

    if (isIosWrapper() && !allowIosWrapperMeasurement) {
        return;
    }

    if (debug && isDevelopment) {
        console.log(`DD RUM VITAL DURATION ${type} [${name}]: ${description}`, { context });
    }

    if (type === VitalDurationType.START) {
        datadogRum.startDurationVital(name, { description, context: { ...context } });

        // Clear any existing timeout for the same vital to prevent duplicate timers
        if (activeVitals.has(name)) {
            clearTimeout(activeVitals.get(name));
        }

        // Auto-stop the vital after the timeout period to avoid long-running vitals
        const timeoutId = setTimeout(() => {
            datadogRum.stopDurationVital(name, { description: `|auto-stopped| launch took longer than 90 seconds`, context: { ...context } });
            activeVitals.delete(name);
        }, VITAL_TIMEOUT) as unknown as number;

        activeVitals.set(name, timeoutId);
    }

    if (type === VitalDurationType.STOP) {
        datadogRum.stopDurationVital(name, { description, context: { ...context } });

        if (activeVitals.has(name)) {
            clearTimeout(activeVitals.get(name));
            activeVitals.delete(name);
        }
    }
};

export interface IddRumError extends IddRumBase {
    context?: object;
    stack?: string;
}

/**
 * @example
 * const ddErrorRmGameMount = (context: { gameUrl?: string }, use_case: IddUseCases): IddRumError => ({
 *      name: 'RM_GAME_MOUNT',
 *      description: 'RM Open Game',
 *      context,
 *      use_case
 * });
 * ddRumError(ddErrorRmGameMount({}, DD_USE_CASES.GLOBAL))
 */
export const ddRumError = (error: IddRumError, debug = isDevelopment) => {
    const { name, description, context, stack, use_case } = error;

    if (debug && isDevelopment) {
        // eslint-disable-next-line
        console.log(`DD RUM ERROR [${name}]: ${description} - `, { error, use_case });
    }

    datadogRum.addError({ name, message: description, stack }, { ...context, use_case });
};
